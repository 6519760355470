import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const Init = () => {
  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "fff000" }}>
      <h1>Welcome {firebase.auth().currentUser?.displayName}</h1>
      <button onClick={() => firebase.auth().signOut()}>Sign out</button>
    </div>
  );
};

export default Init;
