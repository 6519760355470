import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyDxaMrUbUHxSpQR89MXgNapiyOGTUVqRQw",
  authDomain: "spainstartupjobs-f6404.firebaseapp.com",
  projectId: "spainstartupjobs-f6404",
  storageBucket: "spainstartupjobs-f6404.appspot.com",
  messagingSenderId: "83266261069",
  appId: "1:83266261069:web:4d9226b334bec68f340c04",
  measurementId: "G-L31M7DH6BW",
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
