import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import "./App.css";
import PageWrapper from "./components/PageWrapper";
import MainAuthorization from "./views/authorization/MainAuthorization";
import Init from "./views/welcome/Init";

const App = () => {
  const [authorized, setAuthorized] = useState(false);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setAuthorized(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return (
    <div className="App">
      <PageWrapper>{authorized ? <Init /> : <MainAuthorization />}</PageWrapper>
    </div>
  );
};

export default App;
