import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useEffect, useState } from "react";
import componentStyle from "./TopBar.module.scss";

const TopBar = () => {
  return (
    <div className={componentStyle.container}>
      <LogoContainer />
      <div>
        <PostAdContainer />
        <Profile />
      </div>
    </div>
  );
};

const LogoContainer = () => {
  return (
    <div>
      <h3>Spain Startup Jobs</h3>
    </div>
  );
};

const PostAdContainer = () => {
  return (
    <div>
      <button onClick={() => console.log("Post ad")}>Post an Ad</button>
    </div>
  );
};

const Profile = () => {
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState<firebase.User | null>(null);

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setUser(user);
        setAuthorized(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  return (
    <div>
      {authorized ? <div>{user?.displayName}</div> : <button>Login</button>}
    </div>
  );
};

export default TopBar;
