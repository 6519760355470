import TopBar from "./header/TopBar";
import LeftArea from "./LeftArea";
import RightArea from "./RightArea";
import Footer from "./Footer";

type InitProps = {
  children: React.ReactNode;
};

const Init = ({ children }: InitProps) => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <TopBar />
      <div style={{ display: "flex", height: "100%", width: "100%" }}>
        <LeftArea />
        {children}
        <RightArea />
      </div>
      <Footer />
    </div>
  );
};

export default Init;
